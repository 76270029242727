<template>
  <div :class="[
    'header',
    headerStyle ? 'header-white' : '',
    backShow ? 'header-fixed' : '',
  ]">
    <div class="v-container">
      <div class="header-box">
        <img height="28" src="@/assets/img/innaio-logo@2x.png" class="logo" @click="logoClick" alt="" />
        <div class="nav-menu">
          <div class="nav-item">
            <el-dropdown placement="bottom-start" :teleported="false" @visible-change="visibleChange"
              :class="{ active: show }">
              <span class="el-dropdown-link">
                Products
                <el-icon>
                  <ArrowDownBold />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="item in products" :key="item.name">
                    <a :href="item.url" target="_blank">{{ item.name }}</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="nav-item">
            <a href="javascript:void(0);" @click="navTo('/pricing')">Pricing</a>
          </div>
          <!-- <div class="btn" v-if="showHome" @click="showMeiqia">Consult Now</div> -->
        </div>
        <!-- <div class="nav-menu">
          <div class="nav-item">
            <a href="https://laipic.ai/" target="_blank">Laipic Offical Web</a>
          </div>
          <div class="nav-item">
            <a href="https://doratoon.com/" target="_blank">Laipic Doratoon</a>
          </div>
          <div class="nav-item">
            <a href="https://chat.laipic.ai/" target="_blank"
              >Laipic AI Assistant</a
            >
          </div>
          <div class="nav-item">
            <a href="https://frame.laipic.ai/" target="_blank"
              >Laipic AI Frame</a
            >
          </div>
          <div class="nav-item pricing-nav">
            <a href="javascript:void(0);" @click="navTo('/pricing')">Pricing</a>
          </div>
        </div> -->
        <!-- <div class="contact-btn" @click="showMeiqia">Contact us</div> -->
        <div class="login-btn" @click="navTo(loginStore.loginStatus ? '/workbench/home' : '/login')">
          Try Panda AI Avatar for Free
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount, computed } from "vue";
// import { showMeiqia } from "../../utils/index.ts";
// import { storeToRefs } from "pinia";
import { useLoginStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import { ArrowDownBold } from "@element-plus/icons-vue";
// import { handleAssetsUrl } from "@laipic/utils";

const router = useRouter();
const route = useRoute();
const header = ref(["home"]);
const backShow = ref(false);
const headerStyle = ref(true);
// const showHome = ref(false);
const loginStore = useLoginStore();
const show = ref(false);

// const { userInfo, loginStatus } = storeToRefs(loginStore);
const products = ref([
  {
    name: "Panda 72",
    url: import.meta.env.VITE_NODE_ENV == "development" ? "https://innaiobeta.laihua.com/" : "https://www.panda72.ai",
  },
  {
    name: "URL to Video Ads",
    url: import.meta.env.VITE_NODE_ENV == "development" ? "https://innaiobeta.laihua.com/urltovideo" : "https://www.panda72.ai/urltovideo",
  },
  {
    name: "Panda Doratoon",
    url: "https://doratoon.com/",
  },
  {
    name: "Panda Visual AI Agent",
    url: "https://www.panda72.ai/chatsdk/editor",
  },
  {
    name: "Panda AI Store",
    url: "https://framestore.laipic.ai/",
  },
]);

watch(route, () => {
  console.log("route: ", route);
  header.value.forEach((item) => {
    if (item == route.name) {
      headerStyle.value = false;
    } else {
      headerStyle.value = true;
      //   console.log("==", item, route.name, headerStyle.value);
    }
  });
});

onMounted(() => {
  window.addEventListener("scroll", () => {
    if (document.body.scrollTop || document.documentElement.scrollTop > 0) {
      backShow.value = true;
    } else {
      backShow.value = false;
    }
  });
  //   if (
  //     window.location.pathname != "/" &&
  //     window.location.pathname != "/laipic-innaio/"
  //   ) {
  //     // showHome.value = false;
  //     // backShow.value =
  //   }
  console.log("route.name: ", route.name);

  if (route.name == "home") {
    headerStyle.value = false;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", () => { });
});

const logoClick = () => {
  if (loginStore.loginStatus) {
    router.push("/workbench/home");
  } else {
    router.push("/login");
  }
};

function navTo(url: string) {
  router.push(url);
}
function visibleChange(val: boolean) {
  show.value = val;
}
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  //   position: relative;
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s;
  z-index: 9;

  .header-box {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logo {
    // position: absolute;
    // top: 29px;
    // left: 38px;
    margin-top: 2px;
    cursor: pointer;
  }

  .nav-menu {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: Inter-Medium, Inter;
    margin-left: 90px;

    .nav-item {
      margin-left: 90px;
      line-height: 23px;

      &:nth-child(1) {
        margin-left: 0;
      }

      &.active {
        a {
          color: #7c63fa !important;
        }
      }

      :deep(.el-dropdown) {
        font-size: 16px;
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        color: #000;
        line-height: 23px;
        text-decoration: unset;
        cursor: pointer;

        a {
          font-size: 14px;
          font-family: Inter-Regular, Inter;
          font-weight: 400;
          color: #000000;
          line-height: 21px;
          text-decoration: unset;

          &:hover {
            transform: translateX(5px);
            color: #7c63fa !important;
          }
        }

        .el-popper {
          width: 200px;
          // height: 106px;
          background: #ffffff;
          border-radius: 6px 6px 6px 6px;
          padding: 21px 17px;
          box-sizing: border-box;
          text-align: left;
        }

        .el-dropdown-menu__item {
          padding: 0;
          display: block;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .el-dropdown-menu__item:not(.is-disabled):focus {
          background: #fff;
        }

        .el-dropdown-menu {
          padding: 0;
        }

        .el-popper__arrow {
          display: none;
        }

        .el-dropdown-link {
          &:focus-visible {
            outline: unset;
          }
        }

        .el-icon {
          font-size: 14px;
          transition: all 0.3s;
        }

        &.active {
          .el-icon {
            transform: rotate(-180deg);
          }
        }

        &:hover {
          color: #7c63fa !important;
        }
      }

      a {
        font-size: 16px;
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        color: #000;
        line-height: 23px;
        text-decoration: unset;

        &:hover {
          color: #7c63fa !important;
        }
      }
    }
  }

  .contact-btn {
    width: 125px;
    height: 34px;
    background: #6248ff;
    border-radius: 5px 5px 5px 5px;
    font-size: 14px;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: #ffffff;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .login-btn {
    width: 246px;
    height: 50px;
    background: #7c63fa;
    border-radius: 6px;
    font-size: 16px;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .pricing-nav {
    a {
      font-size: 20px;
      font-family: Inter-Semi Bold;
      font-weight: normal;
      color: #7c63fa !important;
      line-height: 20px;
    }
  }

  .user-box {
    position: relative;
    overflow: hidden;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .menu {
      width: 200px;
      position: absolute;
      z-index: 1;
      border: 1px solid #333;
      //   bottom: -100%;
      height: 0;
      bottom: 0;
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover {
      .menu {
        height: 100%;
      }
    }
  }

  &.header-white {
    background: #fff;

    a {
      color: #000 !important;
    }

    .el-dropdown {
      color: #000 !important;
    }
  }

  &.header-fixed {
    transition: all 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
