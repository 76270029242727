<template>
    <div class="dashboard">
        <Header></Header>
        <div class="dashboard-content">
            <LeftMenu></LeftMenu>
            <div class="view-content" v-infinite-scroll="sroload" infinite-scroll-distance="50">
                <RouterView />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { events } from "@/utils/bus.js";
import { RouterView } from 'vue-router'
import Header from '@/components/dashboard/header.vue'
import LeftMenu from '@/components/dashboard/leftMenu.vue'
import { useRouter } from "vue-router";
import { useLoginStore } from "@/store";
const user = useLoginStore();
const router = useRouter();

const sroload = ()=>{
    events.emit('pdscrolling')
}

onMounted(() => {
    window.addEventListener("popstate", ()=> {
        if(!user.loginStatus){
            router.push('/login')
        }
    });
});
onUnmounted(() => {
  window.removeEventListener("popstate",()=>{});
});
</script>

<style lang="scss" scoped>
 .dashboard {
    width: 100%;
    height: 100vh;
    .dashboard-content{
        width: 100%;
        min-width: 1073px;
        height: calc(100% - 81px);
        display: flex;
        background: #fff;
        .view-content{
            flex: 1;
            width: calc(100vw - 239px);
            overflow-y: auto;
        }
    }
}
</style>
  