<template>
  <div class="create-video" @mouseenter="create = true" @mouseleave="create = false">
    <div class="createbtn">
        <img src="@/assets/img/leftmenu/add.png" alt="" width="20">
        Creat video
    </div>
    <transition name="el-zoom-in-top">
        <div class="createbox" v-show="create">
            <div class="size-wrap">
                <p class="tit">Create new video from</p>
                <div class="size">
                    <div class="left" @click="openWin('/broadcasttool')">
                        <div class="box">+</div>
                        <p>16:9</p>
                    </div>
                    <div class="right" @click="openWin('/broadcasttool?direction=2')">
                        <div class="boxs">+</div>
                        <p>9:16</p>
                    </div>
                </div>
            </div>
            <ul>
                <li class="import" @click="deliverClick">
                    <img src="@/assets/img/leftmenu/import.png" alt="" width="25">
                    Import PPT
                    <input
                        ref="input"
                        type="file"
                        accept=""
                        name="files[]"
                        style="display:none;"
                        @change.stop="changeHandler($event)">
                </li>
                <li class="gpt">
                    <img src="@/assets/img/leftmenu/gpt.png" alt="" width="25">
                    Generate script
                    <p>Coming soon</p>
                </li>
            </ul>
        </div>
    </transition>
     <el-dialog v-model="pptVisible" class="pptDialog"
        append-to-body
        @close="pptVisible = face"
        width="500px">
        <div class="transformppt">
            <img src="@/assets/img/leftmenu/ppt-ing.png" alt="" width="280">
            <div class="load">
                <el-icon class="is-loading" :size="20">
                    <Loading />
                </el-icon>
                The PPT is being uploaded...
            </div>
        </div>
     </el-dialog>
  </div>
</template>
<script setup lang="ts">
    import { openWin } from "@/utils";
    import { ref } from "vue";
    import { useLoginStore } from "@/store";
    import { ElMessage } from "element-plus";
    import axios from "axios";
    const userInfo = useLoginStore()

    const create = ref(false)
    const pptVisible= ref(false)

    const input = ref(null)
    const targetFile = ref({})
    const sizeLimit = ref(false)
    const deliverClick = ()=>{
        input.value.click();
    }
    /**
     * 选中文件后处理手柄
     */
    const changeHandler = async(e:any)=> {
        let files = e.target.files;
        if (!files.length) return;
        targetFile.value = files[0];
        // 限制文件上传数量
        if (files.length > 1) {
            alert("Multi file upload is not supported!");
            clearTargetFile();
            return;
        }
        // 限制文件后缀
        if (!/\.(pptx|ppt|dps)$/i.test(targetFile.value.name)) {
            ElMessage({
                message:
                    "Sorry, this feature only supports ppt, pptx and dps (WPS) file formats.",
                type: "error"
            });
            clearTargetFile();
            return;
        }

        // 限制文件大小
        const size = targetFile.value.size / 1024 / 1024;
        sizeLimit.value = size > 10 && size < 100;
        if (size >= 200) {
            ElMessage({
                showClose: true,
                duration: 0,
                message: "The size of the PPT you uploaded is more than 200M, it is recommended to simplify and then try importing.",
                type: "error"
            });
            clearTargetFile();
            return;
        }

        // 开始本地读取文件
        let reader = new FileReader();
        reader.readAsDataURL(targetFile.value);
        reader.onloadstart = e => {
            // 设置ppt转换弹窗显示
            pptVisible.value = true
            clearTargetFile();
        };
        reader.onprogress = e => {
            console.log('onReadProgress', e)
        };
        // 本地文件读取完成（无论读取成功还是失败）
        // 成功的话reader.result值为文件，否则为null
        reader.onloadend = e => {
            console.log('onReadEnd', e)
        };
        // 本地文件读取完成且成功
        reader.onload = async e => {
            // 通过reader.result可以拿到base64格式文件进行本地预览
            // this.$emit("onRead", { reader, e });
            uploadFile(targetFile.value)
                .then((res) => {
                    // this.$emit("onLoadFinished", data);
                    if (res.data.code === 200) {
                            ElMessage.success("Upload successful, about to jump to the editor…");
                            console.log(res.data.data,11123)
                            pptVisible.value = false
                            setTimeout(()=>{
                                window.location.href = `${location.origin}/broadcasttool?ppt=${res.data.data.filename}`;
                            }, 3000);
                    } else {
                        clearTargetFile();
                        pptVisible.value = false
                        ElMessage({
                            message:
                                "Sorry, your file failed to upload, please try uploading again.",
                            type: "error"
                        });
                    }
                })
                .catch(err => {
                    // this.$emit("onLoadFailed", err);
                    clearTargetFile();
                    pptVisible.value = false
                    ElMessage({
                        message: "Sorry, your file failed to upload, please try uploading again.",
                        type: "error"
                    });
                });
            };
        }
        /**
         * 上传ppt
         */
        const uploadFile = (file:any)=> {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append("files[]", file);
                let body = {
                    url: "/innaio-api/upload/ppt",
                    method: "POST",
                    data: formData,
                    headers: "'Content-Type': 'multipart/form-data; boundary=---12321"
                };
                axios(body)
                    .then((res:any) => resolve(res))
                    .catch((err:any) => reject(err));
            });
        }
        // }
        // 解决第二次点击input不会触发onchange事件的bug
        const clearTargetFile = ()=> {
            if (input.value) {
                input.value.value = "";
            }
        }
</script>
<style lang="scss" scoped>
.create-video {
    width: 164px;
    height: 35px;
    position: relative;
    margin: 0 28px;
    cursor: pointer;
    .createbtn{
        width: 164px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #7C63FA;
        border-radius: 6px 6px 6px 6px;
        font-size: 16px;
        font-family: 'Inter-Medium';
        font-weight: 500;
        color: #FFFFFF;
        &:hover{
            background: #4C2EE6;
        }
        img{
            margin-right: 8px;
        }
    } 
    .createbox{
        position: absolute;
        top: 64px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 304px;
        background: #FFFFFF;
        box-shadow: 0px 0px 22px 2px rgba(70,76,89,0.13);
        border-radius: 10px 10px 10px 10px;
        z-index: 9;
        ul{
            width: 100%;
            padding: 12px 0;
            li{
                height: 48px;
                display: flex;
                align-items: center;
                padding-left: 30px;
                font-family: 'Inter-Medium';
                img{
                    margin-right: 4px;
                }
            }
            .import{
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                &:hover{
                    background: rgb(242, 242, 243);
                }
            }
            .gpt{
                font-size: 16px;
                font-weight: 500;
                color: #707070;
                p{
                    width: 78px;
                    height: 18px;
                    text-align: center;
                    line-height: 18px;
                    background: linear-gradient(90deg, #7974F9 0%, #9232F3 100%);
                    border-radius: 22px 22px 22px 22px;
                    font-size: 10px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-left: 7px;
                }
            }
        }
        .size-wrap{
            width: 100%;
            .tit{
                font-size: 18px;
                font-family: 'Inter-Semi Bold';
                font-weight: normal;
                color: #333333;
                line-height: 68px;
                padding-left: 30px;
                text-align: left;
            }
            .size{
                box-sizing: border-box;
                margin: 0 35px 0 22px;
                padding-bottom: 18px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #E9E9E9;
            }
            .left,.right{
                width: 102px;
                height: 116px;
                cursor: pointer;
                &:hover{
                    background: rgba(243, 243, 243, 1);
                }
                p{
                    font-size: 16px;
                    font-family: 'Inter-Medium';
                    font-weight: 500;
                    color: #333333;
                    line-height: 16px;
                    text-align: center;
                }
            }
            .box{
                width: 67px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #7974F9;
                border-radius: 2px 2px 2px 2px;
                font-size: 16px;
                color: #FFFFFF;
                margin: 25px 0 28px 17px;
            }
            .boxs{
                width: 36px;
                height: 67px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #7974F9;
                border-radius: 2px 2px 2px 2px;
                font-size: 16px;
                color: #FFFFFF;
                margin: 9px 0 14px 33px;
            }
        }
    }
}
</style>
<style lang="scss">
    .pptDialog{
        .el-dialog__header{
            padding: 10px 0 0 0;
        }
        .transformppt{
            text-align: center;
            .load{
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                font-size: 16px;
                i{
                    margin-right: 6px;
                }
            }
        }
    }
</style>