<template>
  <div class="footer">
    <div class="v-container">
      <div class="row">
        <div class="col">
          <img height="28" src="@/assets/img/b-logo.png" alt="" />
          <div class="text-box">
            <div class="label"> </div>
            <img width="56" src="@/assets/img/index/iso@2x.png" alt="" />
          </div>
        </div>
        <div class="col">
          <div class="title">About Panda 72</div>
          <div class="text-box">
            <a v-for="item in abouts" @click="scrollTo(item)" :key="item.label" href="javascript:void(0);"
              class="label">{{ item.label }}</a>
          </div>
        </div>
        <div class="col">
          <div class="title">Products</div>
          <div class="text-box">
            <a v-for="item in products" :key="item.label" :href="item.url" class="label" target="_blank">{{ item.label
              }}</a>
          </div>
        </div>
        <div class="col">
          <div class="title">Contact Us</div>
          <div class="text-box">
            <div class="label">support@panda72.ai</div>
            <div class="label">Find Us On Social Media</div>
            <div class="icon-box">
              <a href="https://www.facebook.com/profile.php?id=61550910375870" target="_blank">
                <img width="32" src="@/assets/img/index/facebook@2x.png" />
              </a>
              <a href="https://www.instagram.com/panda72ai/" target="_blank">
                <img width="29" src="@/assets/img/index/ig.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const abouts = ref([
  {
    label: "Our Partners",
    url: "#partner",
  },
  {
    label: "How It Works",
    url: "#works",
  },
  {
    label: "One Video Maker For Every Team",
    url: "#maker",
  },
  {
    label: "Features",
    url: "#features",
  },
  {
    label: "What Customers Say",
    url: "#customers",
  },
]);
const products = ref([
  {
    label: "Panda 72",
    url: "https://www.panda72.ai",
  },
  {
    label: "URL to Video Ads",
    url: import.meta.env.VITE_NODE_ENV == "development" ? "https://innaiobeta.laihua.com/urltovideo" : "https://www.panda72.ai/urltovideo",
  },
  {
    label: "Panda Doratoon",
    url: "https://doratoon.com",
  },
  // {
  //     label: "Laipic InnAIO",
  //     url: "https://www.panda72.ai/video/"
  // },
  {
    label: "Panda Visual AI Agent",
    url: "https://www.panda72.ai/chatsdk/editor",
  },
  {
    label: "Panda AI Store",
    url: "https://aistore.panda72.ai",
  },
]);

function scrollTo(item: any) {
  console.log("---", item.url);
  const el = document.querySelector(`${item.url}`) as HTMLElement;
  window.scrollTo({
    top: el.offsetTop,
    behavior: "smooth",
  });
}
</script>
<style lang="scss" scoped>
.footer {
  color: #ffffff;
  padding-top: 76px;
  padding-bottom: 111px;
  width: 100%;
  height: 466px;
  background: #0d1a38;
  box-sizing: border-box;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .col {
    // width: 20%;
    text-align: left;
    // &:first-child {
    //     width: 30%;
    // }
  }

  .title {
    font-size: 18px;
    font-family: Inter-SemiBold, Inter;
    font-weight: 600;
    line-height: 35px;
    color: rgba(255, 255, 255, 0.6);
  }

  .text-box {
    margin-top: 33px;
    display: flex;
    flex-direction: column;

    .label {
      font-size: 16px;
      font-family: Inter-SemiBold, Inter;
      font-weight: 600;
      color: #ffffff;
      line-height: 18px;
      margin-bottom: 29px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 18px;
      }
    }
  }
}
</style>
