<template>
  <div class="user-head" @mouseenter="user = true" @mouseleave="user = false">
    <div class="headimg">
        <img :src="userInfo.userInfo.headImgUrl?handleAssetsUrl(userInfo.userInfo.headImgUrl):'https://fresource.laihua.com/image/profile.png'" alt="">
        <p v-if="!userInfo.isFree" :class="`sign${userInfo.isPro?'6':'4'}`">{{userInfo.isFree?'Free':userInfo.isPro?'Pro':'Pro+'}}</p>
    </div>
    <transition name="el-zoom-in-top">
      <ul class="user-wrap" v-show="user">
        <li class="username">
          <p class="name">{{userInfo.userInfo.nickname}}</p>
          <p class="mail">{{userInfo.userInfo.mailAddr}}</p>
        </li>
        <li class="hli" @click="router.push('/workbench/account')">
          <img src="@/assets/img/leftmenu/set.png" alt="" width="22">
          Account settings
        </li>
        <li class="hli" @click="loginout">
          <img src="@/assets/img/leftmenu/log.png" alt="" width="22">
          Log out
        </li>
      </ul>
    </transition>
  </div>
</template>
<script setup lang="ts">
import { requireImg, handleAssetsUrl } from "@/utils";
  import { ref } from "vue";
  import { ElMessage } from "element-plus";
  import { useLoginStore } from "@/store";
  import axios from "axios";
  import { useRouter } from "vue-router";

  const router = useRouter();
  const userInfo = useLoginStore()

  const user = ref(false)

  const loginout = async()=>{
      const { data } = await axios.put("/innaio-api/logout");
      if (data.code !== 200) {
        ElMessage.error(data.msg);
        return
      }
      userInfo.changeLogin({})
      router.push('/');
  }
</script>
<style lang="scss" scoped>
.user-head {
  width: 50px;
  height: 50px;
  position: relative;
  background: #fff;
  .headimg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    p{
      width: 42px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #D9F9E6;
      border-radius: 3px 3px 3px 3px;
      font-size: 12px;
      font-family: 'Inter-Medium';
      font-weight: 500;
      color: #009840;
      position: absolute;
      left: 40px;
      // transform: translate(-50%, 0);
      top: -5px;
      z-index: 6;
    }
    .sign0{
      background: #D9F9E6;
      color: #009840;
    }
    .sign6{
      background: #EFEEFF;
      color: #8379FF;  
    }
    .sign4{
      background: #F7EDFE;
      color: #BE71FB;
    }
  }
  .user-wrap{
    position: absolute;
    padding-bottom: 10px;
    right: 0;
    top: 64px;
    width: 266px;
    background: #FFFFFF;
    box-shadow: 0px 0px 22px 2px rgba(70,76,89,0.13);
    border-radius: 10px 10px 10px 10px;
    box-sizing: border-box;
    z-index: 9;
    .username{
      border-bottom: 1px solid #E7E7E7;
      text-align: left;
      margin-bottom: 10px;
      padding: 22px 18px 30px 24px;
      .name{
        font-size: 18px;
        font-family: 'Inter-Medium';
        font-weight: 500;
        color: #000000;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .mail{
        font-size: 14px;
        font-family: 'Inter-Regular';
        font-weight: 400;
        color: #646464;
        line-height: 14px;
      }
    }
    .hli{
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      font-family: 'Inter-Regular';
      font-weight: 400;
      color: #000000;
      padding: 0 0 0 24px;
      box-sizing: border-box;
      img{
        margin-right: 15px;
      }
      &:hover{
        background: rgba(243, 243, 243, 1);
      }
    }
  }
}
</style>
