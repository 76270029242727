import axios from "axios";
import { ElMessage } from "element-plus";
import { defineStore } from "pinia";

const useVipStore = defineStore("vip", {
  state: () => ({
    vipInfo: [],
    orderInfo: {},
    payStatus: 0, // 0:未支付,1:支付中,2:支付成功,3:支付失败
    payType: 1, // 1:paypal
    proPriceArr: [],
    proplusPriceArr: [],
    timer: null,
  }),
  getters: {
    double: (state) => state.count * 2,
    proPriceArr: (state) => state.vipInfo["数字人Pro版会员"] || [],
    proplusPriceArr: (state) => state.vipInfo["数字人Pro+版会员"] || [],
  },
  actions: {
    // 获取会员信息
    async getVipInfo() {
      const { data } = await axios.get("/innaio-api/user/vipLevelInfo");
      if (data.code == 200) {
        this.vipInfo = data.data;
        return Promise.resolve(true);
      } else {
        return Promise.reject(false);
      }
    },
    // 生成订单
    async getOrderInfo(params: any) {
      const { data } = await axios.get("/innaio-api/user/vip/orders/new", {
        params: params,
      });
      if (data.code == 200) {
        this.orderInfo = data.data;
        return Promise.resolve(true);
      } else {
        this.orderInfo = {};
        ElMessage.error(data.msg);
        location.reload();
        return Promise.reject(false);
      }
    },
    // 检查是否完成支付
    async checkFinished(tradeNo: any) {
      const { data } = await axios.get("/innaio-api/payment/checkFinished", {
        params: {
          tradeNo: tradeNo,
        },
      });
      if (data.code == 200) {
        ElMessage.success("pay success");
        this.payStatus = 2;
        localStorage.setItem("payStatus", "2");
        this.stopPolling();
      } else if (data.code == 412 && this.payStatus != 2) {
        this.startPolling(tradeNo);
        this.payStatus = 1;
        localStorage.setItem("payStatus", "1");
      } else {
        this.payStatus = 3;
        localStorage.setItem("payStatus", "3");
        this.stopPolling();
      }
    },
    //开始轮询
    startPolling(tradeNo: any) {
      const timer = setTimeout(() => {
        this.checkFinished(tradeNo);
      }, 3000);
      this.timer = timer;
    },
    // 结束轮询
    stopPolling() {
      window.clearTimeout(this.timer);
    },
  },
});

export default useVipStore;
