import { defineStore } from "pinia";

const useGlobalStore = defineStore("global", {
  state: () => ({
    // count: 0,
  }),
  getters: {
    isDev() {
      console.log(import.meta);
      return import.meta.env.VITE_NODE_ENV == "development";
    },
  },
  actions: {
    // increment() {
    //   this.count++;
    // },
  },
});

export default useGlobalStore;
