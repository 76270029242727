<template>
  <div class="dashboard-header">
    <div class="logo">
      <img height="28" src="@/assets/img/innaio-logo@2x.png" class="logoimg" alt="" />
    </div>
    <div class="rightbox">
      <div class="pricingbox" @click="router.push('/pricing')">Pricing</div>
      <CreateProject></CreateProject>
      <UserHead></UserHead>
    </div>
  </div>
</template>
<script setup lang="ts">
import UserHead from "@/components/dashboard/userHead.vue";
import CreateProject from "@/components/dashboard/createProject.vue";
import { useRouter } from "vue-router";
const router = useRouter();
</script>
<style lang="scss" scoped>
.dashboard-header {
  width: 100%;
  height: 80px;
  position: relative;
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 46px;
  box-sizing: border-box;

  .rightbox {
    display: flex;
    align-items: center;

    .pricingbox {
      width: 103px;
      height: 35px;
      text-align: center;
      line-height: 33px;
      background: #FFFCF1;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      border: 1px solid #FFE3AC;
      box-sizing: border-box;
      font-size: 16px;
      font-family: 'Inter-Medium';
      font-weight: 500;
      color: #333333;
      cursor: pointer;

      &:hover {
        background: #FFC69C;
      }
    }
  }
}
</style>
