import axios from "axios";

export function requireImg(url: string) {
  return new URL(`../assets/img/${url}`, import.meta.url).href;
}

export function showMeiqia() {
  if (!window) return;
  function allSetFn() {
    // console.log("美洽网站插件初始化完毕。");
    // console.log(location.hostname);
  }
  if (window && !window._MEIQIA) {
    (function (a: any, b: any, c: any, d: any, j?: any, s?: any) {
      a[d] =
        a[d] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (a[d].a = a[d].a || []).push(arguments);
        };
      (j = b.createElement(c)), (s = b.getElementsByTagName(c)[0]);
      j!.async = true;
      j.charset = "UTF-8";
      j.src = "https://static.meiqia.com/widget/loader.js";
      s.parentNode.insertBefore(j, s);
    })(window, document, "script", "_MEIQIA");
    window._MEIQIA("entId", "b05d5bfe5d2856b1bf4ff58ce9e926b0");
    window._MEIQIA("language", "en");
    window._MEIQIA("manualInit");
    window._MEIQIA("allSet", allSetFn);
    window._MEIQIA("init");
  }
  window._MEIQIA("showPanel");
}

// export function puaPlayVideo(){
//   let videos = document.getElementsByTagName('video')
//   videos.forEach(el => {
//     el.pause()
//   });
// }

export function handleAssetsUrl(url: string) {
  if (!url) return;
  const httpReg = /^http/;
  const reg = /^\//;
  const slashHttpReg = /^\/http/;
  const qiniuAssetsUrl = 'https://fresource.laihua.com';
  if (httpReg.test(url) || url.indexOf("base64") != -1) {
    // 兼容第三方登录的头像地址为http的情况，替换为https
    return url.replace(/http:/, "https:");
  }
  // 兼容以/开头的绝对路径：/http://xxxxx.com
  if (slashHttpReg.test(url)) {
    return url.substr(1);
  } else if (reg.test(url)) {
    return qiniuAssetsUrl + url;
  } else {
    return qiniuAssetsUrl + "/" + url;
  }
}

/**
 * 生成uuid
 */
export function generateUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * 处理时间戳
 * 返回格式：yyyy-mm-dd hh:mm
 */
export const tsFil = (ts: string | number | Date, isHou: Boolean) => {
  if (!ts) return;
  //分钟必须是两位数
  function checkTime(t: number | string) {
    if (t < 10) {
      t = "0" + t;
      return t;
    } else {
      return t;
    }
  }
  //浏览器兼容，不能用toLocaleString()
  ts = new Date(Number(ts) * 1000);

  const y = ts.getFullYear();
  const mon = ts.getMonth() + 1;
  const day = ts.getDate();
  const hou = ts.getHours();
  const min = checkTime(ts.getMinutes());

  let time;
  if (isHou) {
    time = `${y}-${mon}-${day} ${hou}:${min}`;
  } else {
    time = `${y}-${mon}-${day}`;
  }
  return time;
};

export const openWin = (url:String)=> {
  const tagA = document.createElement('a')
  tagA.href = url
  tagA.target = '_blank'
  tagA.click() // 点击事件
  tagA.remove()
}
/**
 * 秒转化为分钟格式
 * @param {String} secs 秒
 * @returns 分钟格式
 */
 export const secToMin = (secs:number)=> {
  return [
      // parseInt(secs / 60 / 60),
      parseInt((secs / 60) % 60),
      parseInt(secs % 60)
  ]
      .join(':')
      .replace(/\b(\d)\b/g, '0$1')
}
/**
 * 截取本地视频/线上视频的指定帧为封面。
 * 参数：url:需要截封面的视频地址，线上的绝对路径或者`URL.createObjectURL(file)`。
 * 返回：res为截图上传到服务器后返回的内容。
 */
export   function getVideoThumbnail(url, currentTime = 2) {
  return new Promise((resolve, reject) => {
      let video = document.createElement("video");
      // video.crossOrigin = "anonymous";
      video.crossOrigin = "";
      video.currentTime = currentTime;
      let crop = () => {
          let timer = setTimeout(() => {
              video.removeEventListener("loadeddata", crop);
              clearTimeout(timer);
              let canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              let ctx = canvas.getContext("2d");
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              canvas.toBlob(dataUrl => {
                  let formData = new FormData();
                  formData.append("imageName", dataUrl);
                  axios({
                      url: "/innaio-api/upload/file",
                      method: "post",
                      data: formData 
                  })
                      .then(res => {
                          resolve(res);
                      })
                      .catch(err => {
                          console.log("err:", err);
                          reject(err);
                      });
              });
          }, 1000);
      };
      video.addEventListener("loadeddata", crop);
      video.setAttribute("src", url);
  }).catch(reason => {
      console.log("catch:", reason);
  });
}
/**
 * 下载视频
 * @param {string} url 视频资源链接
 * @param {string} name 指定视频名称
 * @param { string } type 如需下载其他格式文件可以传入类型
 */
export function downloadVideo (url, name, type) {
  // 火狐浏览器中不能以流的形式下载
  if (type === 1 && !GetMobelType().isFF) {
      const a = document.createElement('a')
      a.download = name
      a.href = url
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
      return
  }
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer' // 返回类型blob
  xhr.onload = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
          const blob = this.response
          // 转换一个blob链接
          const u = window.URL.createObjectURL(
              new Blob([blob])
          )
          const a = document.createElement('a')
          a.download = name
          a.href = u
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
      }
  }
  xhr.send()
}
/**
 * 判断设备类型
 * @returns 包含各设备信息的对象
 */
export function GetMobelType () {
  const browser = {
      versions: (function () {
          const ua = window.navigator.userAgent
          return {
              trident: ua.indexOf('Trident') > -1, // IE内核
              presto: ua.indexOf('Presto') > -1, // opera内核
              Alipay: ua.indexOf('Alipay') > -1, // 支付宝
              webKit: ua.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
              gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') == -1, // 火狐内核
              mobile: !!ua.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
              ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
              android: ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1, // android终端或者uc浏览器
              iPhone: ua.indexOf('iPhone') > -1 || ua.indexOf('Mac') > -1, // 是否为iPhone或者安卓QQ浏览器
              // iPhone: ua.match(/iphone|ipod|ipad/),//
              iPad: ua.indexOf('iPad') > -1, // 是否为iPad
              webApp: ua.indexOf('Safari') == -1, // 是否为web应用程序，没有头部与底部
              weixin: ua.indexOf('MicroMessenger') > -1, // 是否为微信浏览器
              qq: ua.match(/\sQQ/i) !== null, // 是否QQ
              Safari: ua.indexOf('Safari') > -1, // Safari浏览器,
              weibo: ua.indexOf('Weibo') > -1, // 是否微博
              isMobile: /(iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone|micromessenger|)/gi.test(ua),
              isEdge: ua.includes('Edge'),
              isFF: ua.includes('Firefox'),
              isBB: ua.includes('BlackBerry'),
              isChrome: ua.includes('Chrome'),
              isMaxthon: ua.includes('Maxthon'),
              isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua),
              isIE: ['compatible', 'MSIE'].every(n => ua.includes(n)),
              isSafari: ua.includes('Safari') && !ua.includes('Chrome')
          }
      })()
  }
  return browser.versions
}