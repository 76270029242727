import { DirectiveBinding } from 'vue';

function onMouseEnter(event: MouseEvent) {
    const el = event.target as HTMLElement;
    el.style.boxShadow  = 'rgba(64, 85, 128, 0.16) 0px 4px 12px'; 
    el.style.transform = 'translateY(-4px)'; 
}

function onMouseLeave(event: MouseEvent) {
    const el = event.target as HTMLElement;
    el.style.border = '';
    el.style.boxShadow  ='';
    el.style.transform = '';
}

export const hoverCard = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        el.addEventListener('mouseenter', onMouseEnter);
        el.addEventListener('mouseleave', onMouseLeave);
        el.style.transition = 'all 200ms ease 0s';
    },
    unmounted(el: HTMLElement, binding: DirectiveBinding) {
        el.removeEventListener('mouseenter', onMouseEnter);
        el.removeEventListener('mouseleave', onMouseLeave);
    }
};

export const focus = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value) {
            el.focus();
        }
    }
};