<template>
    <div>
        404
    </div>
</template>
<script setup lang="ts">
    
</script>
<style lang="" scoped>
    
</style>