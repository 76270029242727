import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";

const useLoginStore = defineStore("login", {
  state: () => ({
    userInfo: {},
  }),
  getters: {
    loginStatus: (state) => !!Object.keys(state.userInfo).length,
    userLevelType: ({ userInfo: ui }) => ui.chartletLevelType,
    isFree: ({ userInfo: ui }) =>
      ui.level === 0 && ui.levelType === 0 && !ui.chartletLevelNewType,
    // 是否个人 数字人pro版 (包含月度和年度)
    isPro: ({ userInfo: ui }) =>
      ui.chartletLevelNewType
        ? ui.chartletLevelType === 0 &&
          (ui.chartletLevelNewType === 13 || ui.chartletLevelNewType === 14)
        : ui.chartletLevel === 0 &&
          (ui.levelNewType === 13 || ui.levelNewType === 14),

    // 是否企业 数字人pro+版 (包含月度和年度)
    isProPlus: ({ userInfo: ui }) =>
      ui.chartletLevelNewType
        ? ui.chartletLevelType === 2 &&
          (ui.chartletLevelNewType === 15 || ui.chartletLevelNewType === 16)
        : ui.chartletLevel === 0 &&
          (ui.levelNewType === 15 || ui.levelNewType === 16),
  },
  actions: {
    changeLogin(data: any) {
      this.userInfo = Object.keys(data).length ? data : {};
    },
    /**
     * 获取session
     */
    async fetchUserInfo() {
      const { data } = await axios.get("/innaio-api/session");
      if (data.code === 200) {
        this.changeLogin(data.data);
        console.log("==", this.loginStatus);
      } else {
        const router = useRouter();
        router.push("/login");
      }
    },
    async getSession() {
      const { data } = await axios.get("/innaio-api/session");
      if (data.code === 200) {
        this.changeLogin(data.data);
        return true;
      } else {
        return false;
      }
    },
    /**
     * 刷新session
     */
    async refreshSession() {
      const { data } = await axios.get("/innaio-api/session/new");
      if (data.code === 200) {
        this.changeLogin(data.data);
      } else {
        const router = useRouter();
        router.push("/login");
      }
    },
    /**
     * 退出登录
     */
    async logout() {
      const { data } = await axios.put("/innaio-api/logout");
      if (data.code !== 200) {
        return;
      }
    },
    /**
     * google 登录
     */
    async googleLogin() {
      const { data } = await axios.put("/webapi/logout");
      if (data.code !== 200) {
        return;
      }
    },
  },
});
export default useLoginStore;
