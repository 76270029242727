import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router/index";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import { createPinia } from "pinia";
import * as dictive from "./utils/directive";
import Icons from "@/icon/index";
import { pinia } from "@/store";

// const pinia = createPinia();
const app = createApp(App);
const dictValue = Object.values(dictive);
Object.keys(dictive).forEach((item, i) => {
  app.directive(item, dictValue[i]);
});
app.use(pinia).use(router).use(ElementPlus).mount("#app");

// 注册全局element icon组件
Object.keys(Icons).forEach((it) => {
  app.component(it, (Icons as any)[it]);
});
