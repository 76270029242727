import { markRaw } from "vue";

// 按需引入图标
import { Loading, ArrowLeftBold,CloseBold,Camera,QuestionFilled,ArrowRightBold,FullScreen,Microphone,Mute,VideoPause,VideoPlay } from '@element-plus/icons-vue';

const Icons = markRaw({
    Loading,
    ArrowLeftBold,
    CloseBold,
    Camera,
    QuestionFilled,
    ArrowRightBold,
    FullScreen,
    Microphone,
    Mute,
    VideoPause,
    VideoPlay
});

export default Icons;
