<template>
  <div class="layout-wrap">
    <Header v-if="!headerVisible"></Header>
    <RouterView />
    <Footer v-if="!footerVisible"></Footer>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { useLoginStore } from "@/store";
const route = useRoute();

const hiddenList = ["orders", "workbench", "ads", "broadcast/urltovideo", "creation"];

const headerVisible = computed(() => {
  return hiddenList.some((val) => {
    return route.path.includes(val);
  });
});

const footerList = ["pricing", "payment", "orders", "workbench", "ads", "broadcast/urltovideo", "creation"];
const footerVisible = computed(() => {
  return footerList.some((val) => {
    return route.path.includes(val);
  });
});

const loginStore = useLoginStore();

const authPages = ["payment", "orders", "workbench"];
const router = useRouter();
const fetchUserInfo = async () => {
  const { data } = await axios.get("/innaio-api/session");
  if (data.code === 200) {
    loginStore.changeLogin(data.data);
  } else {
    if (authPages.some((value) => route.path.includes(value))) {
      router.push("/login");
      ElMessage.error(data.msg);
    }
    loginStore.changeLogin({});
  }
};

const infoVisible = computed(() => {
  const arr = ["broadcast/urltovideo", "creation"];
  return arr.some((val) => {
    return route.path.includes(val);
  });
});
console.log(infoVisible.value, 1112)
if (!infoVisible.value) {
  fetchUserInfo();
}
</script>

<style lang="scss" scoped>
.layout-wrap {
  width: 100%;
  min-height: 100%;
  background: #fff;
}
</style>
