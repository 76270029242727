import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../view/NotFound.vue";
import workbench from "../view/workbench/index.vue";
import layout from "../layout/index.vue";
import { useLoginStore, pinia } from "@/store";

const loginStore = useLoginStore(pinia);
console.log("useLoginStore: ", loginStore.loginStatus);

const routes = [
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/",
    name: "home",
    redirect: "/",
    component: layout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/view/index/index.vue"),
      },
      {
        path: "/pricing",
        name: "pricing",
        component: () => import("@/view/pricing/index.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/view/pricing/orders.vue"),
      },
      {
        path: "/payment",
        name: "payment",
        component: () => import("@/view/pricing/payment.vue"),
      },
      {
        path: "/broadcast/urltoadv",
        name: "urltovideo",
        component: () => import("@/view/broadcast/urltovideo.vue"),
      },
      {
        path: "/broadcast/urltovideo/:id?",
        name: "editproduct",
        component: () => import("@/view/ads/edit-product.vue"),
        meta: {
          title: "URL to Video Ads - Create Al Video Ads with Product URL"
        }
      },
      {
        path: "/ads/generate-script/:id?",
        name: "generatescript",
        component: () => import("@/view/ads/generate-script.vue"),
        meta: {
          title: "URL to Video Ads - Create Al Video Ads with Product URL"
        }
      },
      {
        path: "/ads/video-setting/:id?",
        name: "videosetting",
        component: () => import("@/view/ads/video-setting.vue"),
        meta: {
          title: "URL to Video Ads - Create Al Video Ads with Product URL"
        }
      },
      {
        path: "/ads/preview/:id?",
        name: "preview",
        component: () => import("@/view/ads/preview.vue"),
        meta: {
          title: "URL to Video Ads - Create Al Video Ads with Product URL"
        }
      },
      {
        path: "/creation/explain",
        name: "explain",
        component: () => import("@/view/creation/explain.vue"),
      },
      {
        path: "/creation/upload",
        name: "upload",
        component: () => import("@/view/creation/upload.vue"),
      },
      {
        path: "/creation/recording",
        name: "recording",
        component: () => import("@/view/creation/recording.vue"),
      },
      {
        path: "/creation/authorization",
        name: "authorization",
        component: () => import("@/view/creation/authorization.vue"),
      },
      {
        path: "/workbench",
        name: "workbench",
        redirect: "/workbench/home",
        component: workbench,
        children: [
          {
            path: "/workbench/home",
            name: "Home",
            component: () => import("@/view/workbench/home.vue"),
          },
          {
            path: "/workbench/video",
            name: "Video",
            component: () => import("@/view/workbench/video.vue"),
          },
          {
            path: "/workbench/template",
            name: "Template",
            component: () => import("@/view/workbench/template.vue"),
          },
          {
            path: "/workbench/avatar",
            name: "Avatar",
            component: () => import("@/view/workbench/avatar.vue"),
          },
          {
            path: "/workbench/voice",
            name: "Voice",
            component: () => import("@/view/workbench/voice.vue"),
          },
          {
            path: "/workbench/assest",
            name: "Assest",
            component: () => import("@/view/workbench/assest.vue"),
          },
          {
            path: "/workbench/trash",
            name: "Trash",
            component: () => import("@/view/workbench/trash.vue"),
          },
        ],
      },
      {
        path: "/workbench/account",
        name: "account",
        component: () => import("@/view/account/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/view/user/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/view/user/register/index.vue"),
  },
  {
    path: "/auth-login",
    name: "auth-login",
    component: () => import("@/view/user/auth-login/index.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/view/agreement.vue"),
  },
];
console.log("router------->import", import.meta.env.VITE_NODE_ENV);
const pth =
  import.meta.env.VITE_NODE_ENV === "development" ? "/laipic-innaio" : "/video";
const router = createRouter({
  history: createWebHistory(pth),
  routes,
});

// router.onError((error: { message: string | string[]; }, to: { fullPath: string; }) => {
//   if (error.message.includes('Failed to fetch dynamically imported module')) {
//     window.location = pth + to.fullPath
//   }
// })
// const authPages = ["payment", "orders", "workbench"];
// router.beforeEach(async (to, from, next) => {
//   if (authPages.some((value) => to.path.includes(value))) {
//     try {
//       const isLogin = await loginStore.getSession();
//       console.log(isLogin, 99998);
//       if (!isLogin) {
//         next("/login");
//       } else {
//         next();
//       }
//     } catch (error) {
//       console.error("Failed", error);
//       next("/login");
//     }
//   } else {
//     next();
//   }
// });

export default router;
