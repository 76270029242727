<template>
  <div class="left-menu">
    <div class="menu-item" :class="{ active: item.url == route.path }" v-for="item in memus" :key="item.name"
      @click="goTo(item)">
      <div class="icon">
        <img :src="item.icon" alt="" width="12" />
      </div>
      <span>{{ item.name }}</span>
    </div>
    <p class="line"></p>
    <p class="title titles">Creative tool</p>
    <div class="menu-item menu-items"
      @click="openWin(dev ? 'https://innaiobeta.laihua.com/urltovideo' : 'https://www.panda72.ai/urltovideo')">
      <span>URL to Video Ads</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { openWin } from "@/utils";
import { ref } from "vue";
import { requireImg } from "@/utils";
import { useRouter, useRoute } from "vue-router";

const dev = import.meta.env.VITE_NODE_ENV == 'development'

const router = useRouter();
const route = useRoute();
const memus = ref([
  {
    id: 0,
    name: "Home",
    url: "/workbench/home",
    icon: requireImg("leftmenu/icon0.png"),
    active: true,
  },
  {
    id: 1,
    name: "Video",
    url: "/workbench/video",
    icon: requireImg("leftmenu/icon1.png"),
    active: false,
  },
  {
    id: 2,
    name: "Template",
    url: "/workbench/template",
    icon: requireImg("leftmenu/icon2.png"),
    active: false,
  },
  {
    id: 3,
    name: "Avatar",
    url: "/workbench/avatar",
    icon: requireImg("leftmenu/icon3.png"),
    active: false,
  },
  {
    id: 4,
    name: "Voice",
    url: "/workbench/voice",
    icon: requireImg("leftmenu/icon4.png"),
    active: false,
  },
  {
    id: 5,
    name: "Assest",
    url: "/workbench/assest",
    icon: requireImg("leftmenu/icon5.png"),
    active: false,
  },
  {
    id: 6,
    name: "Trash",
    url: "/workbench/trash",
    icon: requireImg("leftmenu/icon6.png"),
    active: false,
  },
]);

const goTo = (item: any) => {
  router.push(item.url);
};
</script>
<style lang="scss" scoped>
.left-menu {
  width: 175px;
  padding: 44px 32px 0;
  height: calc(100% - 44px);

  .menu-item {
    width: 100%;
    height: 18px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #5f5f5f;
    cursor: pointer;
    margin-bottom: 20px;
    font-family: "Inter-Medium";

    .icon {
      width: 18px;
      height: 18px;
      background: #8a8a8a;
      border-radius: 4px 4px 4px 4px;
      margin: 0 10px 0 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      background: #f4f3ff;
      color: #5c56ec;

      .icon {
        background: #6c67e7;
      }
    }

    &.active {
      background: #f4f3ff;
      color: #5c56ec;

      .icon {
        background: #6c67e7;
      }
    }
  }

  .menu-items {
    padding-left: 16px;
    position: relative;

    img {
      position: absolute;
      right: 32px;
      top: -6px;
    }
  }

  .line {
    width: 99px;
    height: 1px;
    background: #e7e7e7;
    margin: 30px 0 30px 16px;
  }

  .title {
    font-size: 16px;
    padding: 0 0 28px 16px;
    text-align: left;
    font-weight: 400;
    color: #8f8f8f;
  }

  .titles {
    background: linear-gradient(90deg, #5c7ae3 0%, #9340e5 100%);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
  }
}
</style>
